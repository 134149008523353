import React from 'react';

const Unauthorized = () => {
  return (
    <div>
      <h1>Unauthorized</h1>
      <h2>You do not have permission to view this page.</h2>
    </div>
  );
};

export default Unauthorized;
